<template>

    <nav v-if="logged_in" class="navbar navbar-expand-lg navbar-light" style="background-color: rgba(34, 31, 32, 0.83); min-height: 150px;">
        <div class="navbar-nav" style="width:100%; display: flex;justify-content: space-between !important;  margin-left: 15%; margin-right: 15%;">
            <router-link to="/">
                <div v-if="app_owner==='HKIG'" style="display: flex; flex-direction: row">
                    <img src="../../img/logo/hkig.png" height="70" width="auto"/>
                    <div style="display: flex; flex-direction: column;padding-top: 15px">
                        <span style="color: white; padding-left: 15px">HRVATSKA KOMORA </span>
                        <span style="color: white; padding-left: 15px">INŽENJERA GRAĐEVINASRTVA</span>
                    </div>
                </div>
                <div v-if="app_owner==='HKIS'" style="display: flex; flex-direction: row">
                    <img src="../../img/logo/hkig.png" height="70" width="auto"/>
                    <div style="display: flex; flex-direction: column;padding-top: 15px">
                        <span style="color: white; padding-left: 15px">HRVATSKA KOMORA </span>
                        <span style="color: white; padding-left: 15px">INŽENJERA STROJARSTVA</span>
                    </div>
                </div>
                <div v-if="app_owner==='HKIE'" style="display: flex; flex-direction: row">
                    <img src="../../img/logo/hkie.png" height="70" width="auto"/>
                    <div style="display: flex; flex-direction: column;padding-top: 15px">
                        <span style="color: white; padding-left: 15px">HRVATSKA KOMORA </span>
                        <span style="color: white; padding-left: 15px">INŽENJERA ELEKTROTEHNIKE</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: center">
                    <div @click="toggleMenu" class="burger-icon" :class="{ open: isMenuOpen }" style="margin-top:10px">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

            </router-link>



            <!-- Links -->
            <div class="navbar-links" :class="{ open: isMenuOpen }">
                <div class="test">
                    <a :href="'doc/' + app_owner.toLowerCase() + '.pdf'" download class="nav-link btn navbar-item" style="color: white; margin-left: 20px; margin-right: 20px"> <strong>UPUTE ZA KORIŠTENJE</strong> </a>
                </div>

                <div v-if="logged_in" class="nav-item active"
                     style="display: flex; justify-content: center; padding-top: 15px;">
                    <div v-if="app_owner==='HKIE'">
                        <button style="border-radius: 10px;background-color: #00a6db;border:none;">
                            <a @click.prevent="logout" class="nav-link btn navbar-item" style="color: white; margin-left: 20px; margin-right: 20px"> <strong>ODJAVA</strong> </a>
                        </button>
                    </div>
                    <div v-else style="">
                        <button style="border-radius: 10px;background-color: red;border:none;">
                            <a @click.prevent="logout" class="nav-link btn navbar-item" style="color: white; margin-left: 20px; margin-right: 20px"> <strong>ODJAVA</strong> </a>
                        </button>
                    </div>
                </div>
            </div>

<!--            <div v-if="logged_in" class="nav-item active"-->
<!--                style="display: flex; flex-direction: row; padding-top: 15px;">-->
<!--                <div>-->
<!--                    <a :href="'doc/' + app_owner.toLowerCase() + '.pdf'" download class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"> <strong>UPUTE ZA KORIŠTENJE</strong> </a>-->
<!--                </div>-->
<!--                <div v-if="app_owner==='HKIE'">-->
<!--                    <button style="border-radius: 10px;background-color: #00a6db;border:none;">-->
<!--                        <a @click.prevent="logout" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"> <strong>ODJAVA</strong> </a>-->
<!--                    </button>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                    <button style="border-radius: 10px;background-color: red;border:none;">-->
<!--                        <a @click.prevent="logout" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"> <strong>ODJAVA</strong> </a>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
        </div>






    </nav>


</template>

<script>


import axios from "axios";
import toastr from "toastr"
import { mapGetters, mapActions } from "vuex";
export default {
    name: "NavigationTop",
    data(){
        return {
            logged_in: false,
            app_owner: process.env.VUE_APP_APP_OWNER,
            open_dropdown:true,
            isMenuOpen: false,
        }
    },
    computed: {
        ...mapGetters("auth", ["user"])
    },
    methods:{
        goto( name ){
            this.$router.push(name)
        },

        ...mapActions("auth", ["getUserData"]),

        logout() {
            let vm = this;
            axios.post(process.env.VUE_APP_API_URL + "logout", {
                email: vm.email,
                password: vm.password
            })
            .then(response => {
                if( response.data.success ){
                    vm.$store.commit("auth/setUserData", null) ;
                    localStorage.removeItem("access_token" );
                    vm.logged_in = false;
                    vm.$router.push("/login")
                } else {
                    toastr.error('Došlo je do greške')
                }
            })
            .catch( function ( error ) {
                if(error instanceof Error) {
                    console.log( 'Error: ' . error);
                } else {
                    console.log( 'Unexpected response: ' . error);
                }
            } )
        },

        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        }
    },
    mounted() {
        if (localStorage.getItem("access_token")) {
            this.getUserData();
            if ( this.user )
                this.logged_in = true
        }
    },
    watch:{
        user: function(){
            if ( this.user )
                this.logged_in = true
        }
    }

}


</script>

<style scoped>
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 1rem;
}

.burger-icon {
    display: none;
    cursor: pointer;
    width: 25px;
    flex-direction: column;
    justify-content: space-around;
    height: 25px;
}

.burger-icon span {
    background-color: white;
    height: 3px;
    width: 100%;
    //margin: 3px 0;
    transition: all 0.3s;
}

.burger-icon.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.burger-icon.open span:nth-child(2) {
    opacity: 0;
}

.burger-icon.open span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
}

.navbar-links {
    display: flex;
    list-style: none;
}

.navbar-links.open {
    display: flex;
    flex-direction: column;
}

.navbar-item {
   padding-top: 10px;
    color: white;
    text-decoration: none;
}

@media (max-width: 991px) {
    .burger-icon {
        display: flex;
    }

    .navbar-links {
        display: none;
        width: 100%;
        text-align: center;
    }
}
@media (min-width: 992px) {
  .test{
      padding-top: 17px;
  }
}

</style>