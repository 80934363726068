<template>
    <div>
        <div class="container">

            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                    :active.sync="open_loader"
                    :can-cancel="false"
                    :is-full-page="true"
                    :color="'red'"
                >
                </loading>
            </div>
            <div class="row" style="margin-top: 20%;">
                <div class=" col-lg-12">
                    <div class="container" style="color: black;">
                        <div class="row loginCard" style=" border-radius: 25px;">

                            <InfoCardElement/>
                            <div class="col-lg-6 loginCardElement">

                                <p class="h4" style="text-align: center; padding: 20px 35px 0px 35px;">
                                    <span style="color: rgb(23 37 84);">Registracija</span>
                                    <br><br>
                                </p>

                                <form class="form-horizontal" style="padding: 0 45px 0 45px">

                                    <div :class="{'form-group': true}">

                                        <label
                                            for="ime"
                                            class="control-label"
                                        >
                                            Ime *
                                        </label>

                                        <input
                                            id="ime"
                                            type="text"
                                            class="form-control  inputCss"
                                            name="ime"
                                            v-model="ime"
                                            required
                                            style="color: black"
                                        >

                                        <span
                                            v-if="errors && errors.ime"
                                            class="help-block text-danger"
                                        >
                                                                                            <strong>
                                                                                                {{ errors.ime[0] }}
                                                                                            </strong>
                                                                                        </span>

                                    </div>


                                    <div :class="{'form-group': true}">

                                        <label
                                            for="prezime"
                                            class="control-label"
                                        >
                                            Prezime*
                                        </label>

                                        <input
                                            id="prezime"
                                            type="text"
                                            class="form-control inputCss"
                                            name="prezime"
                                            v-model="prezime"
                                            required
                                            style="color: black"
                                        >

                                        <span
                                            v-if="errors && errors.prezime"
                                            class="help-block text-danger"
                                        >
                                                                                            <strong>
                                                                                                {{ errors.prezime[0] }}
                                                                                            </strong>
                                                                                        </span>

                                    </div>

                                    <div :class="{'form-group': true}">

                                        <label
                                            for="oib"
                                            class="control-label"
                                        >
                                            OIB*
                                        </label>

                                        <input
                                            id="oib"
                                            type="text"
                                            class="form-control inputCss"
                                            name="oib"
                                            v-model="oib"
                                            required
                                            style="color: black;"
                                        >

                                        <span
                                            v-if="errors && errors.oib"
                                            class="help-block text-danger"
                                        >
                                                                                        <strong>
                                                                                            {{ errors.oib[0] }}
                                                                                        </strong>
                                                                                    </span>

                                    </div>

                                    <div :class="{'form-group': true}">

                                        <label
                                            for="email"
                                            class="control-label"
                                        >
                                            E-mail*
                                        </label>

                                        <input
                                            id="email"
                                            type="email"
                                            class="form-control inputCss"
                                            name="email"
                                            v-model="email"
                                            required
                                            style="color: black"
                                        >

                                        <span
                                            v-if="errors && errors.email"
                                            class="help-block text-danger"
                                        >
                                                                                        <strong>
                                                                                            {{ errors.email[0] }}
                                                                                        </strong>
                                                                                    </span>

                                    </div>

                                    <div :class="{'form-group': true}">

                                        <label
                                            for="password"
                                            class="control-label"
                                        >
                                            Upišite proizvoljnu lozinku*
                                        </label>

                                        <input
                                            id="password"
                                            type="password"
                                            class="form-control inputCss"
                                            name="password"
                                            v-model="password"
                                            required
                                            style="color: black"
                                        >

                                        <span
                                            v-if="errors && errors.password"
                                            class="help-block text-danger"
                                        >
                                                                                        <strong>
                                                                                            {{ errors.password[0] }}
                                                                                        </strong>
                                                                                    </span>

                                    </div>

                                    <div :class="{'form-group': true}">

                                        <label
                                            for="password_confirm"
                                            class="control-label"
                                        >
                                            Ponovite lozinku*
                                        </label>

                                        <input
                                            id="password_confirm"
                                            type="password"
                                            class="form-control inputCss"
                                            name="password_confirm"
                                            v-model="password_confirm"
                                            required
                                            style="color: black;"
                                        >

                                        <span
                                            v-if="errors && errors.password_confirm"
                                            class="help-block text-danger"
                                        >
                                                                                        <strong>
                                                                                            {{
                                                                                                errors.password_confirm[0]
                                                                                            }}
                                                                                        </strong>
                                                                                    </span>

                                    </div>


                                    <div class="form-group">
                                        <div class="col-lg-12" style="text-align: center; ">
                                            <button
                                                type="submit"
                                                class="btn btn-xl btn-secondary registerButton"
                                                @click.prevent="register"
                                            >
                                                <strong>Kreiraj račun</strong>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div
                                    style="display: flex;flex-direction: row; justify-items: center; text-align: center;align-items: center">
                                    <div style="width: 33.33%">
                                        <hr>
                                    </div>
                                    <p style=" text-align: center;width: 33.33%; padding-top: 10px;">
                                        ili
                                    </p>
                                    <div style="width: 33.33%">
                                        <hr>
                                    </div>
                                </div>


                                <p style="color:  #e13131; text-align: center; padding-top: 15px;">
                                    <span class="col-lg-12" style="text-align: center; ">
                                        <button
                                            type="submit"
                                            class="btn btn-xl btn-secondary registerButton"
                                            @click.prevent="login"

                                        >
                                            <strong> Vrati se nazad</strong>
                                        </button>
                                    </span>

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            <div class="row">-->
            <!--                <div class=" col-md-6">-->
            <!--                    <div class="container" style="margin-top: 50px; color: white">-->
            <!--                        <div class="row" style="background-color: rgba(34, 31, 32, 0.83);">-->
            <!--                            <div class="col-md-12" style="margin: 50px; max-width: 80%">-->
            <!--                                <p class="h3" style="text-align: center;">-->
            <!--                                    <img src="@/img/navigation/login.png" style="width: 100px"> <br><br> <strong>REGISTRACIJA</strong>-->
            <!--                                    <br><br>-->
            <!--                                </p>-->
            <!--                                <p>-->
            <!--                                    Kreirajte svoj korisnički račun ako ga već nemate.-->
            <!--                                    <br><br>-->
            <!--                                </p>-->

            <!--                                <form class="form-horizontal">-->

            <!--                                    <div :class="{'form-group': true}">-->

            <!--                                        <label-->
            <!--                                                for="ime"-->
            <!--                                                class="control-label"-->
            <!--                                        >-->
            <!--                                            Ime *-->
            <!--                                        </label>-->

            <!--                                        <input-->
            <!--                                                id="ime"-->
            <!--                                                type="text"-->
            <!--                                                class="form-control"-->
            <!--                                                name="ime"-->
            <!--                                                v-model="ime"-->
            <!--                                                required-->
            <!--                                                style="color: black"-->
            <!--                                        >-->

            <!--                                        <span-->
            <!--                                                v-if="errors && errors.ime"-->
            <!--                                                class="help-block text-danger"-->
            <!--                                        >-->
            <!--                                                <strong>-->
            <!--                                                    {{ errors.ime[0] }}-->
            <!--                                                </strong>-->
            <!--                                            </span>-->

            <!--                                    </div>-->


            <!--                                    <div :class="{'form-group': true}">-->

            <!--                                        <label-->
            <!--                                                for="prezime"-->
            <!--                                                class="control-label"-->
            <!--                                        >-->
            <!--                                            Prezime*-->
            <!--                                        </label>-->

            <!--                                        <input-->
            <!--                                                id="prezime"-->
            <!--                                                type="text"-->
            <!--                                                class="form-control"-->
            <!--                                                name="prezime"-->
            <!--                                                v-model="prezime"-->
            <!--                                                required-->
            <!--                                                style="color: black"-->
            <!--                                        >-->

            <!--                                        <span-->
            <!--                                                v-if="errors && errors.prezime"-->
            <!--                                                class="help-block text-danger"-->
            <!--                                        >-->
            <!--                                                <strong>-->
            <!--                                                    {{errors.prezime[0] }}-->
            <!--                                                </strong>-->
            <!--                                            </span>-->

            <!--                                    </div>-->

            <!--                                    <div :class="{'form-group': true}">-->

            <!--                                        <label-->
            <!--                                                for="oib"-->
            <!--                                                class="control-label"-->
            <!--                                        >-->
            <!--                                            OIB*-->
            <!--                                        </label>-->

            <!--                                        <input-->
            <!--                                                id="oib"-->
            <!--                                                type="text"-->
            <!--                                                class="form-control"-->
            <!--                                                name="oib"-->
            <!--                                                v-model="oib"-->
            <!--                                                required-->
            <!--                                                style="color: black;"-->
            <!--                                        >-->

            <!--                                        <span-->
            <!--                                                v-if="errors && errors.oib"-->
            <!--                                                class="help-block text-danger"-->
            <!--                                        >-->
            <!--                                                <strong>-->
            <!--                                                    {{errors.oib[0] }}-->
            <!--                                                </strong>-->
            <!--                                            </span>-->

            <!--                                    </div>-->

            <!--                                    <div :class="{'form-group': true}">-->

            <!--                                        <label-->
            <!--                                                for="email"-->
            <!--                                                class="control-label"-->
            <!--                                        >-->
            <!--                                            E-mail*-->
            <!--                                        </label>-->

            <!--                                        <input-->
            <!--                                                id="email"-->
            <!--                                                type="email"-->
            <!--                                                class="form-control"-->
            <!--                                                name="email"-->
            <!--                                                v-model="email"-->
            <!--                                                required-->
            <!--                                                style="color: black"-->
            <!--                                        >-->

            <!--                                        <span-->
            <!--                                                v-if="errors && errors.email"-->
            <!--                                                class="help-block text-danger"-->
            <!--                                        >-->
            <!--                                                <strong>-->
            <!--                                                    {{errors.email[0] }}-->
            <!--                                                </strong>-->
            <!--                                            </span>-->

            <!--                                    </div>-->

            <!--                                    <div :class="{'form-group': true}">-->

            <!--                                        <label-->
            <!--                                                for="password"-->
            <!--                                                class="control-label"-->
            <!--                                        >-->
            <!--                                            Upišite proizvoljnu lozinku*-->
            <!--                                        </label>-->

            <!--                                        <input-->
            <!--                                                id="password"-->
            <!--                                                type="password"-->
            <!--                                                class="form-control"-->
            <!--                                                name="password"-->
            <!--                                                v-model="password"-->
            <!--                                                required-->
            <!--                                                style="color: black"-->
            <!--                                        >-->

            <!--                                        <span-->
            <!--                                                v-if="errors && errors.password"-->
            <!--                                                class="help-block text-danger"-->
            <!--                                        >-->
            <!--                                                <strong>-->
            <!--                                                    {{errors.password[0] }}-->
            <!--                                                </strong>-->
            <!--                                            </span>-->

            <!--                                    </div>-->

            <!--                                    <div :class="{'form-group': true}">-->

            <!--                                        <label-->
            <!--                                                for="password_confirm"-->
            <!--                                                class="control-label"-->
            <!--                                        >-->
            <!--                                            Ponovite lozinku*-->
            <!--                                        </label>-->

            <!--                                        <input-->
            <!--                                                id="password_confirm"-->
            <!--                                                type="password"-->
            <!--                                                class="form-control"-->
            <!--                                                name="password_confirm"-->
            <!--                                                v-model="password_confirm"-->
            <!--                                                required-->
            <!--                                                style="color: black;"-->
            <!--                                        >-->

            <!--                                        <span-->
            <!--                                                v-if="errors && errors.password_confirm"-->
            <!--                                                class="help-block text-danger"-->
            <!--                                        >-->
            <!--                                                <strong>-->
            <!--                                                    {{errors.password_confirm[0] }}-->
            <!--                                                </strong>-->
            <!--                                            </span>-->

            <!--                                    </div>-->

            <!--                                    <div class="form-group">-->
            <!--                                        <div class="col-md-12" style="text-align: center">-->
            <!--                                            <button-->
            <!--                                                    type="submit"-->
            <!--                                                    class="btn btn-xl btn-secondary"-->
            <!--                                                    @click.prevent="register"-->
            <!--                                                    style="width: 80%"-->
            <!--                                            >-->
            <!--                                                <strong>Kreiraj račun</strong>-->
            <!--                                            </button>-->


            <!--                                        </div>-->
            <!--                                    </div>-->
            <!--                                </form>-->

            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import InfoCardElement from "./InfoCardElement.vue";

export default {
    name: "Register",
    components: {
        InfoCardElement,
        Loading
    },
    data: function () {
        return {
            ime: null,
            prezime: null,
            oib: null,
            email: null,
            password: null,
            password_confirm: null,
            errors: null,
            open_loader: false,
        }
    },
    methods: {
        register() {
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "register", {
                ime: vm.ime,
                prezime: vm.prezime,
                oib: vm.oib,
                email: vm.email,
                password: vm.password,
                password_confirm: vm.password_confirm,
            })
                .then(response => {
                    if (response.data.success) {
                        vm.$router.push('/register-success')
                    } else {
                        vm.errors = response.data.messages
                    }
                    vm.open_loader = false
                })
                .catch(function (error) {
                    if (error instanceof Error) {
                        console.log('Error: '.error);
                    } else {
                        console.log('Unexpected response: '.error);
                    }
                    vm.open_loader = false
                })
        },
        login() {
            this.$router.push('login')
        }
    }
}
</script>

<style scoped>
label{
    margin-left: 6%
}
.help-block{
    margin-left: 6%
}
</style>

<style lang="scss">
.vertical-scrollbar {
    width: 100%;
    height: calc(100vh - 200px);
    flex-grow: 1;

    article {
        padding: 15px;
    }
}
</style>