<template>
<!--    <div style="width: 100%; height: 100%">-->
        <div :style="{
            'position': 'absolute',
            // 'background-attachment': 'fixed',
            //'background-position': 'center',
            'background-image': 'url(' + require('@/img/background/' + image + '.png') +')',
            'background-position': 'center center',
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'height': '100%',
            'width': '100%'
        }"
        >
            <vue-custom-scrollbar class="scroll-area"  :settings="settings" >
                <navigation-top></navigation-top>
                <router-view></router-view>
            </vue-custom-scrollbar>

        </div>


<!--    </div>-->
</template>

<script>
import NavigationTop from "@/views/layout/NavigationTop";
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
export default {
    name: "Home",
    components: {
        NavigationTop,
        vueCustomScrollbar
    },
    data() {
        return {
            settings: {
                suppressScrollY: false,
                suppressScrollX: false,
                wheelPropagation: false,
            },
            image: ''

        }
    },
    mounted() {
        if( process.env.VUE_APP_APP_OWNER === 'HKIG' )
            this.image = 'hkig2'
        else if( process.env.VUE_APP_APP_OWNER === 'HKIE' )
            this.image = 'hkie2'
        else if( process.env.VUE_APP_APP_OWNER === 'HKIS' )
            this.image = 'hkis2'
    }
}
</script>

<style scoped>

</style>

<style >
.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
}
</style>
