<template>
    <div class="row">

        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>

        <div
            :class="width"
        >
            <tabs
                :list="tabs"
                :active="active_tab"
                @change="changeTab"
                @onInput="onInput"
                @returnComponent="returnComponent"
            ></tabs>

            <buttons
                :buttons="buttons"
                @onFormButtonClick="onFormButtonClick"
                style="padding-top: 10px;"
            ></buttons>
        </div>

    </div>
</template>

<script>
import Tabs from "@/components/form/parts/Tabs";
import Buttons from "@/components/form/parts/Buttons"

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';


import axios from "axios";

export default {
    name: "Forms",
    components: {
        Tabs,
        Buttons,
        Loading
    },

    props: {
        settings:{
            type: Object,
            required: true
        }
    },

    data(){
        return {

            finished: false,

            //general
            modal: false,
            width: 'col-md-6',

            // tabs
            active_tab: 0,
            tabs: [],

            // buttons
            buttons: [],

            // data
            data:{},

            // loader
            open_loader: false,

        }
    },

    methods:{
        init(){
            if( this.settings ){
                this.finished = false
                if( this.settings.modal )
                    this.modal = true
                if( this.settings.width )
                    this.width = 'col-md-' + ( this.settings.width ? this.settings.width : 6 )
                this.setupTabs()
                this.setupButtons()
                this.finished = true
            }
        },

        /**
         * TABS
         */
        setupTabs: function() {
            let vm = this;

            if( vm.settings.tabs !== undefined ){
                let tmp_active = 0
                vm.tabs = []
                vm.settings.tabs.forEach( function( tab, index ) {
                    if( tab.name !== undefined ){
                        vm.tabs[index] = {
                            name: tab.name,
                            sections: tab.sections
                        };
                        if( tab.active )
                            tmp_active = index;
                    }
                } )
                // set active tab
                vm.changeTab( tmp_active )
            }

        },
        changeTab( i ){
            this.active_tab = i
        },

        /**
         *  BUTTONS
         */
        setupButtons(){
            let vm = this;
            vm.buttons = []
            if( vm.settings && vm.settings.buttons !== undefined ){
                vm.settings.buttons.forEach( function( button ) {
                    if( button.id !== undefined ){
                        let tmp = {
                            id: button.id ? button.id : '',
                            title: button.title ? button.title : '',
                            type: button.type ? ( 'btn btn-' + button.type ) : 'btn btn-success',
                            action: button.action ? button.action : '',
                        };
                        let config = {}
                        if( tmp.action === 'redirect'){
                            config={
                                url: button.config.url ? button.config.url : ''
                            }
                        }
                        if( tmp.action === 'request') {
                            config={
                                url: button.config.url ? button.config.url : '',
                                method: button.config.method
                            }
                        }
                        tmp['config'] = config;
                        vm.buttons.push( tmp )
                    }
                } )
            }
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            this.data[name] = value
            this.$emit( 'onInput', name, value )
        },

        returnComponent( name, component ){
            this.$emit( 'returnComponent', name, component )
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config ){
            let vm = this

            vm.open_loader = true;

            if( action == 'redirect' )
            {
                vm.$router.push( { path: config.url } )
            } else if( action == 'request' ){
                let confirm = true;
                if( config.method == 'DELETE' ){
                    confirm = window.confirm( 'Da li ste sigurni?');
                }
                if( confirm ){
                    vm.open_loader = true
                    axios({
                        method: config.method,
                        url: config.url,
                        data: vm.data
                    }).then((response) => {
                        vm.open_loader = false
                        vm.$emit( 'onFormButtonClick', id,  action, config, response.data )
                    }, (error) => {
                        vm.open_loader = false
                        console.log(error);
                    });
                }
            } else {
                vm.open_loader = false;
                vm.$emit( 'onFormButtonClick', id, action, config, vm.data )
            }
        }

    },

    mounted() {
        this.init();
    },

    watch:{
        settings: {
            deep: true,
            handler: function(){
                this.init()
            }
        }
    },
}
</script>

<style scoped>

</style>