<template>
    <div>
        <div class="container ">
            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                    :active.sync="open_loader"
                    :can-cancel="false"
                    :is-full-page="true"
                    :color="'red'"
                >
                </loading>
            </div>
            <div class="row" style="margin-top: 20%;">
                <div class=" col-lg-12">
                    <div class="container" style="color: black;" >
                        <div class="row loginCard" style=" border-radius: 25px;">

<!--                            <div class="col-lg-6 infoCardElement">-->
<!--                                <p class="h3" style="text-align: center;margin-top: 70px">-->
<!--                                    <img src="@/img/logo/hkig-white.png" style="width: auto; max-width:200px;">-->
<!--                                    <br><br>-->
<!--                                </p>-->
<!--                                <p class="h4" style="text-align: center; padding: 20px 35px 0px 35px;">-->
<!--                                    <span style="color: white;">Dobrodošli na sustav stručnog usavršavanja!</span>-->
<!--                                </p>-->
<!--                                <p style="color: white;font-size: large;text-align: justify;" class="infoCardElement-text">-->
<!--                                    Unesite svoje podatke i pridružite se platformi za edukaciju inženjera građevinarstva,-->
<!--                                    Vašem portalu za stručni rast i razvoj.-->
<!--                                    <br><br>-->
<!--                                </p>-->
<!--                                &lt;!&ndash;                                    download button za upute&ndash;&gt;-->

<!--                                &lt;!&ndash;                                    <div class="" style="margin-top: 130px;">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        <a href="doc/hkig.pdf" download class="nav-link btn" style="color: white;"><img src="@/img/navigation/upute.png" style="width: 10%">  <strong>UPUTE ZA KORIŠTENJE</strong> </a>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                <div class="button-container" style="padding-top: 20px; margin-bottom: 70px;">-->
<!--                                    <a href="doc/hkig.pdf" download class="Documents-btn-link">-->
<!--                                        <button class="Documents-btn">-->
<!--                                      <span class="folderContainer">-->
<!--                                        <svg-->
<!--                                            xmlns="http://www.w3.org/2000/svg"-->
<!--                                            fill="none"-->
<!--                                            viewBox="0 0 146 113"-->
<!--                                            height="113"-->
<!--                                            width="146"-->
<!--                                            class="fileBack"-->
<!--                                        >-->
<!--                                          <path-->
<!--                                              fill="url(#paint0_linear_117_4)"-->
<!--                                              d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z"-->
<!--                                          ></path>-->
<!--                                          <defs>-->
<!--                                            <linearGradient-->
<!--                                                gradientUnits="userSpaceOnUse"-->
<!--                                                y2="95.4804"-->
<!--                                                x2="72.93"-->
<!--                                                y1="0"-->
<!--                                                x1="0"-->
<!--                                                id="paint0_linear_117_4"-->
<!--                                            >-->
<!--                                              <stop stop-color="#8F88C2"></stop>-->
<!--                                              <stop stop-color="#5C52A2" offset="1"></stop>-->
<!--                                            </linearGradient>-->
<!--                                          </defs>-->
<!--                                        </svg>-->
<!--                                        <svg-->
<!--                                            xmlns="http://www.w3.org/2000/svg"-->
<!--                                            fill="none"-->
<!--                                            viewBox="0 0 88 99"-->
<!--                                            height="99"-->
<!--                                            width="88"-->
<!--                                            class="filePage"-->
<!--                                        >-->
<!--                                          <rect fill="url(#paint0_linear_117_6)" height="99" width="88"></rect>-->
<!--                                          <defs>-->
<!--                                            <linearGradient-->
<!--                                                gradientUnits="userSpaceOnUse"-->
<!--                                                y2="160.5"-->
<!--                                                x2="81"-->
<!--                                                y1="0"-->
<!--                                                x1="0"-->
<!--                                                id="paint0_linear_117_6"-->
<!--                                            >-->
<!--                                              <stop stop-color="white"></stop>-->
<!--                                              <stop stop-color="#686868" offset="1"></stop>-->
<!--                                            </linearGradient>-->
<!--                                          </defs>-->
<!--                                        </svg>-->

<!--                                        <svg-->
<!--                                            xmlns="http://www.w3.org/2000/svg"-->
<!--                                            fill="none"-->
<!--                                            viewBox="0 0 160 79"-->
<!--                                            height="79"-->
<!--                                            width="160"-->
<!--                                            class="fileFront"-->
<!--                                        >-->
<!--                                          <path-->
<!--                                              fill="url(#paint0_linear_117_5)"-->
<!--                                              d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z"-->
<!--                                          ></path>-->
<!--                                          <defs>-->
<!--                                            <linearGradient-->
<!--                                                gradientUnits="userSpaceOnUse"-->
<!--                                                y2="82.8317"-->
<!--                                                x2="66.9106"-->
<!--                                                y1="8.71323"-->
<!--                                                x1="38.7619"-->
<!--                                                id="paint0_linear_117_5"-->
<!--                                            >-->
<!--                                              <stop stop-color="#C3BBFF"></stop>-->
<!--                                              <stop stop-color="#51469A" offset="1"></stop>-->
<!--                                            </linearGradient>-->
<!--                                          </defs>-->
<!--                                        </svg>-->
<!--                                      </span>-->
<!--                                        <p class="text">Upute za korištenje</p>-->
<!--                                    </button>-->
<!--                                    </a>-->

<!--                                </div>-->



<!--                                &lt;!&ndash;                                    end of download button za upute&ndash;&gt;-->

<!--                            </div>-->
                            <InfoCardElement/>
                            <div class="col-lg-6 loginCardElement">
                                <p class="h3" style="text-align: center;margin-top: 70px">
                                </p>
                                <p class="h4" style="text-align: center; padding: 20px 35px 0px 35px;">
                                    <span style="color: rgb(23 37 84);">Prijava</span>
                                    <br><br>
                                </p>

                                <form class="form-horizontal" style="padding: 0 45px 0 45px">

                                    <div :class="{'form-group': true}">

                                        <label style="color: rgb(23 37 84);font-size: large;margin-left: 6%"
                                               for="email"
                                               class="control-label"
                                        >
                                            E-Mail *
                                        </label>

                                        <input

                                            id="email"
                                            type="text"
                                            class="form-control inputCss"
                                            name="email"
                                            v-model="email"
                                            required
                                            autofocus

                                        >

                                        <span
                                            v-if="errors && errors.email"
                                            class="help-block text-danger"
                                        >
                                                    <strong>
                                                        {{ errors.email[0] }}
                                                    </strong>
                                                </span>

                                    </div>


                                    <div :class="{'form-group': true}">

                                        <label style="color: rgb(23 37 84);font-size: large;margin-left: 6%"
                                               for="password"
                                               class="control-label"
                                        >
                                            Lozinka *
                                        </label>

                                        <input
                                            id="password"
                                            type="password"
                                            class="form-control inputCss"
                                            name="password"
                                            v-model="password"
                                            required

                                        >

                                        <span
                                            v-if="errors && errors.password"
                                            class="help-block text-danger"
                                        >
                                                    <strong>
                                                        {{ errors.password[0] }}
                                                    </strong>
                                                </span>

                                    </div>
                                    <p style="color:  #e13131; text-align: right;">
                                        <a role="button" @click="forgotPassword">
                                            Zaboravili ste lozinku?
                                        </a>
                                    </p>


                                    <div class="form-group">
                                        <div class="col-lg-12" style="text-align: center; ">
                                            <button
                                                type="submit"
                                                class="btn btn-xl btn-secondary registerButton"
                                                @click.prevent="login"
                                            >
                                                <strong>Prijavi se</strong>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div style="display: flex;flex-direction: row; justify-items: center; text-align: center;align-items: center">
                                    <div style="width: 33.33%">
                                        <hr>
                                    </div>
                                    <p style=" text-align: center;width: 33.33%; padding-top: 10px;">
                                        Nemate račun?
                                    </p>
                                    <div style="width: 33.33%">
                                        <hr>
                                    </div>
                                </div>


                                <p style="color:  #e13131; text-align: center; padding-top: 15px;">
                                    <span class="col-lg-12" style="text-align: center; ">
                                        <button
                                            type="submit"
                                            class="btn btn-xl btn-secondary registerButton"
                                            @click.prevent="register"

                                        >
                                            <strong> Registriraj se</strong>
                                        </button>
                                    </span>

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                    :active.sync="open_loader"
                    :can-cancel="false"
                    :is-full-page="true"
                    :color="'red'"
                >
                </loading>
            </div>
            <div class="row">
                <div class=" col-lg-6">
                    <div class="container" style="margin-top: 170px; color: black">
                        <div class="row" style="background: white">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import {mapMutations} from "vuex";
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import InfoCardElement from "./InfoCardElement.vue";

export default {
    name: "Login",
    components: {
        InfoCardElement,
        Loading
    },
    data: function () {
        return {
            email: null,
            password: null,
            errors: null,
            open_loader: false,
        }
    },

    methods: {
        ...mapMutations("auth", ["setUserData"]),

        login() {
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "login", {
                email: vm.email,
                password: vm.password
            })
                .then(response => {
                    if (response.data.success) {
                        vm.$store.commit("auth/setUserData", response.data.data.user);
                        localStorage.setItem("access_token", response.data.data.access_token);
                        vm.$router.push('/')
                    } else {
                        vm.errors = response.data.messages
                    }
                    vm.open_loader = false
                })
                .catch(function (error) {
                    if (error instanceof Error) {
                        console.log('Error: '.error);
                    } else {
                        console.log('Unexpected response: '.error);
                    }
                    vm.open_loader = false
                })
        },

        forgotPassword() {
            this.$router.push('forgot-password')
        },

        register() {
            this.$router.push('register')
        }
    },

    mounted() {

    },

}
</script>

<style scoped>
label{
    margin-left: 6%
}



</style>

