<template>
    <div>
        <div class="container">
            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                        :active.sync="open_loader"
                        :can-cancel="false"
                        :is-full-page="true"
                        :color="'red'"
                >
                </loading>
            </div>
            <div v-if="!success" class="row" style="margin-top: 20%;">
                <div class=" col-lg-12">
                    <div class="container" style="color: black;" >
                        <div class="row loginCard" style=" border-radius: 25px;">

                            <InfoCardElement/>
                            <div class="col-lg-6 loginCardElement">
                                <p class="h3" style="text-align: center;margin-top: 70px">
                                </p>
                                <p class="h4" style="text-align: center; padding: 20px 35px 0px 35px;">
                                    <span style="color: rgb(23 37 84);">Registracija</span>
                                    <br><br>
                                </p>
                                <p style="text-align: center;padding-right: 7%; padding-left: 7%;font-size: larger">
                                    Poveznica za potvrdu registracije je neispravna ili je istekla.<br>
                                    Molimo kontaktirajte Vašeg administratora <br><br><a href="mailto:duska.maglica@hkig.hr" style="color: #e13131;">duska.maglica@hkig.hr</a>
                                </p>
                                <form class="form-horizontal" style="padding: 0 45px 0 45px">


                                    <p style="color:  #e13131; text-align: center; padding-top: 15px;">
                                    <span class="col-lg-12" style="text-align: center; ">
                                        <button
                                            type="submit"
                                            class="btn btn-xl btn-secondary registerButton"
                                            @click.prevent="login"

                                        >
                                            <strong>Vrati se nazad</strong>
                                        </button>
                                    </span>

                                    </p>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import InfoCardElement from "./InfoCardElement.vue";
export default {
name: "Verify",
    components: {
        InfoCardElement,
    Loading
    },
    data: function() {
        return {
            success: true,
            open_loader: false,
        }
    },
    methods: {
        verify(){
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "verify", {
                token: this.$route.params.token
            })
                .then(response => {
                    if( response.data.success ){
                        vm.$router.push('/login')
                    } else {
                        vm.success = false
                    }
                    vm.open_loader = false
                })
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                    vm.open_loader = false
                } )
        },
        login() {
            this.$router.push('/login')
        }
    },
    mounted() {
        this.verify();
    }
}
</script>

<style scoped>

</style>
