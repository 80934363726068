<template>
    <div>
        <div class="container">

            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                        :active.sync="open_loader"
                        :can-cancel="false"
                        :is-full-page="true"
                        :color="'red'"
                >
                </loading>
            </div>


            <div class="row" style="margin-top: 20%;">
                <div class=" col-lg-12">
                    <div class="container" style="color: black;" >
                        <div class="row loginCard" style=" border-radius: 25px;">

                           <InfoCardElement/>
                            <div class="col-lg-6 loginCardElement">
                                <p class="h3" style="text-align: center;margin-top: 70px">
                                </p>
                                <p class="h4" style="text-align: center; padding: 20px 35px 0px 35px;">
                                    <span style="color: rgb(23 37 84);">Zaboravili ste lozinku?</span>
                                    <br><br>
                                </p>
                                <p style="text-align: center;padding-right: 10%; padding-left: 10%;font-size: larger">
                                    Upišite E-mail adresu i poveznica za promjenu lozinke će vam biti poslana.

                                </p>
                                <form class="form-horizontal" style="padding: 0 45px 0 45px">

                                    <div :class="{'form-group': true}">

                                        <label style="color: rgb(23 37 84);font-size: large;margin-left: 6%"
                                               for="email"
                                               class="control-label"
                                        >
                                            E-Mail *
                                        </label>

                                        <input

                                            id="email"
                                            type="text"
                                            class="form-control inputCss"
                                            name="email"
                                            v-model="email"
                                            required
                                            autofocus
                                            style="color: black; border-radius: 10px;width: 90%;margin: 0 auto;"
                                        >

                                        <span
                                            v-if="errors && errors.email"
                                            class="help-block text-danger"
                                        >
                                                    <strong>
                                                        {{ errors.email[0] }}
                                                    </strong>
                                                </span>

                                    </div>

                                    <br>
                                    <div class="form-group">
                                        <div class="col-lg-12" style="text-align: center; ">
                                            <button
                                                type="submit"
                                                class="btn btn-xl btn-secondary registerButton"
                                                @click.prevent="forgot_password"
                                            >
                                                <strong>Pošalji</strong>
                                            </button>
                                        </div>
                                    </div>
                                    <div style="display: flex;flex-direction: row; justify-items: center; text-align: center;align-items: center">
                                        <div style="width: 33.33%">
                                            <hr>
                                        </div>
                                        <p style=" text-align: center;width: 33.33%; padding-top: 10px;">
                                            Ili
                                        </p>
                                        <div style="width: 33.33%">
                                            <hr>
                                        </div>
                                    </div>
                                    <p style="color:  #e13131; text-align: center; padding-top: 15px;">
                                    <span class="col-lg-12" style="text-align: center; ">
                                        <button
                                            type="submit"
                                            class="btn btn-xl btn-secondary registerButton"
                                            @click.prevent="login"

                                        >
                                            <strong>Vrati se nazad</strong>
                                        </button>
                                    </span>

                                    </p>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import InfoCardElement from "./InfoCardElement.vue";

export default {
name: "ForgotPassword",
    components: {
        InfoCardElement,
    Loading
    },
    data: function() {
        return {
            email: null,
            errors: null,
            open_loader: false,
        }
    },
    methods: {
        forgot_password(){
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "forgot-password", {
                email: vm.email,
                password: vm.password
            })
                    .then(response => {
                        if( response.data.success ){
                            vm.$router.push( 'forgot-password-success' );
                        } else {
                            vm.errors = response.data.messages
                        }
                        vm.open_loader = false
                    })
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                        vm.open_loader = false
                    } )
        },
        login() {
            this.$router.push('login')
        }
    }
}
</script>

<style scoped>

</style>

<style lang="scss">
.vertical-scrollbar {
    width: 100%;
    height: calc(100vh - 200px);
    flex-grow: 1;
    article {
        padding: 15px;
    }
}
</style>