<template>
    <div>
        <div class="container">
            <div class="row" style="margin-top: 20%;">
                <div class=" col-lg-12">
                    <div class="container" style="color: black;" >
                        <div class="row loginCard" style=" border-radius: 25px;">

                            <InfoCardElement/>
                            <div class="col-lg-6 loginCardElement">
                                <p class="h3" style="text-align: center;margin-top: 70px">
                                </p>
                                <p class="h4" style="text-align: center; padding: 20px 35px 0px 35px;">
                                    <span style="color: rgb(23 37 84);">Zaboravili ste lozinku?</span>
                                    <br><br>
                                </p>
                                <p style="text-align: center;padding-right: 10%; padding-left: 10%;font-size: larger">
                                    Poveznica za promjenu lozinke poslana je na vaš E-mail!

                                </p>
                                <form class="form-horizontal" style="padding: 0 45px 0 45px">
                                    <br>

                                    <p style="color:  #e13131; text-align: center; padding-top: 15px;">
                                    <span class="col-lg-12" style="text-align: center; ">
                                        <button
                                            type="submit"
                                            class="btn btn-xl btn-secondary registerButton"
                                            @click.prevent="login"

                                        >
                                            <strong>Vrati se nazad</strong>
                                        </button>
                                    </span>

                                    </p>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InfoCardElement from "./InfoCardElement.vue";

export default {
    name: "ForgotPasswordSuccess",
    components: {InfoCardElement},
    methods: {
        login() {
            this.$router.push('login')
        }
    },
}
</script>

<style scoped>

</style>