var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
            'position': 'absolute',
            // 'background-attachment': 'fixed',
            //'background-position': 'center',
            'background-image': 'url(' + require('@/img/background/' + _vm.image + '.png') +')',
            'background-position': 'center center',
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'height': '100%',
            'width': '100%'
        })},[_c('vue-custom-scrollbar',{staticClass:"scroll-area",attrs:{"settings":_vm.settings}},[_c('navigation-top'),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }